export default{
    settingsPopup: {
        language: "Language",
        browserDefault: "Configuration par défaut du navigateur",
        en: "Englais",
        fr: "Français",
        viewMode: "Mode d'affichage - beta",
        light: "Clair",
        dark: "Sombre",
        more: "Plus de paramètres utilisateur",
        myAccount: "Mon Compte",
        logout: "Déconnexion"
    }
}
