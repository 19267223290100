import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { AddServiceSectionTitle, DeployAdAdmin } from 'components/Bloc';
import { Spiner } from 'components/Bloc';
import Paypal from 'components/PaypalModal';
import UserAccountConfirmPrompt from 'components/UserAccountConfirmPrompt'
import AddBotom from 'components/vps/AddBotom';
import PromoBottom from 'components/vps/PromoBottom';
import { ServerLocation } from 'components/vps/ServerLocation';
import TrialBottom from 'components/vps/TrialBottom';
import { useTranslation } from 'react-i18next';
import Utils from '../utils';
import UserMgt from '../lib/user_managment';

export default function DeployServerWrapper(props){
    const user = useSelector(app => app?.user?.user)
    const [heightBottomC, setBottomCalculedHeight] = useState(0) 
    const {t} = useTranslation()
    const [link, setlink] = useState(null)
    const { messageApi } = useSelector(app => app.core)
    const { setVpsParams, vps_params, isPromo, setCreating, setPaypal, isTrial, setCodeApplied, setCodeApplying, packSelected, codeApplied, type } = props

    useEffect(() => {
        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }
    }, [])
    async function onApplyCode(code){
        if(!isPromo) return
        try{
            let c = code || vps_params.promoCode
            if(c && c.trim() !== ""){
                setCodeApplying(true)
                const result = await UserMgt.check_promo_code(c);
                setCodeApplying(false)
                if(result && result.code){
                    return setCodeApplied(result?.code)
                }else{
                  return messageApi.error("Invalid promotion code")  
                }
            }else{
                return messageApi.error( "Please enter promotion code first !")
            }
        }catch(e){
            console.log('error applying code', e)
        }
        return messageApi.error("An error occurred, try again or contact us !")
    }

    async function deployVps(paymentId, dataPayment) {
        console.log('vps_params vps_params', vps_params)
        if(props.isVPC){
            return props.customDeploy(paymentId, dataPayment)
        }
        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            messageApi.error(result.message || "An error occurred while running. If this persist, please contact us")
        } else {
            const product_bought = [
                "product_bought", {
                    type: type,
                    name_package: packSelected?.Name,
                    price_package: packSelected.Price,
                    price_paid: calCulFinalPrice(),
                    admin_deployed: vps_params.isAdminFreeDeployment,
                    trial: !!vps_params.trialCode ? vps_params.trialCode : false,
                    promo: !!vps_params.promoCode ? vps_params.promoCode : false,
                },
                "green"
            ]
            if(window.$crisp){
                window.$crisp.push(["set", "session:event", [[ product_bought ]]]);
                vps_params.trialCode ?
                    window.$crisp.push(["set", "session:segments", [["vps", "trial", "lead"]]]) :
                    window.$crisp.push(["set", "session:segments", [["vps", "paying_customer", "lead"]]])
            }
            messageApi.success(result.message || "VPS Instance created")
            const links = result?.result?.links
            const link = links && links.length && links.find(l => l.rel === "approve")
            if(link){
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if(result&& !result.needToConfirm){
                props.functionFinish()
            }
        }

    }
    async function onPay(ev) {
        ev.preventDefault()
        if(link){
            return  window.open(link, "Confirm subscription", "width=500,height=500, fullscreen=yes")
         }
        const validatOject = props.validate()
         if(validatOject.isFail){
            return messageApi.error(validatOject.message)
        }
        setCreating(true)
        if(!vps_params.isAdminFreeDeployment && !Utils.isDemo()){
            if(!isPromo){
                if(vps_params.trialCode && isTrial){
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    const isValid = r && r.code && (!!r.code.IsMulti ||  !(!!r.code.IsUsed ));
                    if(!isValid ){
                        setCreating(false)
                        return messageApi.error("Trial code is not valid !")
                    }
                }
                setPaypal(true)

            }else{
                return deployVps()
            }
        }else{
            return deployVps()
        }
    }

    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid: parseFloat(packSelected?.Price),
        }
        return deployVps(paymentID, dataPayment2)
    }

    function calCulFinalPrice(){
        if(packSelected){
            return parseFloat((packSelected.Price) * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else{
            return 0;
        }
    }

    function onDismiss() {
        setCreating(false)
        setPaypal(false)

    }




    return( 
        <div className='relative pb-5'>
            <div 
                className={user && !user?.EmailConfirmed ?'opacity-[20%]'  : ''} 
                style={{paddingBottom: heightBottomC+10}}
            >
                <>
                    <Paypal
                        createPaymentAndDeploy={createPaymentAndDeploy}
                        open={props.paypal}
                        vpsType={props.packSelected}
                        onDismiss={onDismiss}
                        withFirstTrial={props.isTrial}
                        withPromo={props.isPromo}
                        finalPrice={calCulFinalPrice}
                    />

                    <div className="px-3 md:px-8 relative  dark:text-darkTextColor ">
                        <div className="container max-w-full">
                            <div className='mx-auto'>
                                <ServerLocation 
                                    vps_params={props.vps_params} 
                                    setVpsParams={props.setVpsParams} 
                                    desc={props.locationDesc} 
                                    label={props.label} 
                                    isVpsMini={props.isVpsMini} 
                                />
                                 <AddServiceSectionTitle
                                    title={props?.title || t("deploy.selectPackageSectionTitle")}
                                />
                                <DeployAdAdmin
                                    title={t("deploy.deplayByAdminSectionTitle")}
                                    creating={props.creating}
                                    vps_params={props.vps_params}
                                    setVpsParams={props.setVpsParams}
                                />
                                   
                                {props.concertPack ?
                                    <form onSubmit={onPay} className="">
                                        {props.children}
                                        <div className={''}>
                                            {
                                                props.isTrial?
                                                    <TrialBottom 
                                                        isKps={props?.isKps}
                                                        isSecurity={props?.isSecurity}
                                                        isNetwork={props?.isNetwork}
                                                        creating={props.creating}
                                                        vps_params={props.vps_params}
                                                        paypal={props.paypal}
                                                        packSelected={props.packSelected}
                                                        setBottomCalculedHeight={setBottomCalculedHeight}
                                                        handleChange={props.handleChange}
                                                    />
                                                :
                                                props.isPromo ?
                                                    <PromoBottom 
                                                        isKps={props?.isKps}
                                                        isSecurity={props?.isSecurity}
                                                        isNetwork={props?.isNetwork}
                                                        creating={props.creating}
                                                        vps_params={props.vps_params}
                                                        paypal={props.paypal}
                                                        packSelected={props.packSelected}
                                                        setBottomCalculedHeight={setBottomCalculedHeight}
                                                        handleChange={props.handleChange}
                                                        codeApplied={props.codeApplied}
                                                        onApplyCode={onApplyCode}
                                                        codeApplying={props.codeApplying}
                                                    />
                                                :
                                                <AddBotom 
                                                    isKps={props?.isKps}
                                                    isSecurity={props?.isSecurity}
                                                    isNetwork={props?.isNetwork}
                                                    creating={props.creating}
                                                    vps_params={props.vps_params}
                                                    paypal={props.paypal}
                                                    packSelected={props.packSelected}
                                                    setBottomCalculedHeight={setBottomCalculedHeight}

                                                />

                                            }

                                        </div>
                                    </form>
                                    :
                                    <div className='flex justify-center items-center h-48 '>
                                        <Spiner fontSize={50} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </div>
            {user && !user?.EmailConfirmed ?
                <UserAccountConfirmPrompt user={user} />
                :
                null
            }
        </div>
    )
}