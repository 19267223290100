export default{
    products: "Produits" ,
    database: { label: "Base de données" },
    platform: { label: "Plateformes" },
    kubernetes: { label: "Kubernetes" },
    infrastructure: { label: "Infrastructures" },
    security: { label:"Securités" },
    networking: { label: "Réseaux" },
    storage:{
        label: "Stockages",
        link1: "Blocs de stockage"
    },
    account: {
        label: "Compte",
        link1: "Profile",
        link2: "Récompense",
        link3: "Facturation"
    },
    support: {
        label: "Support"
    },
    order: "Deployer"
}
