import { useEffect } from 'react'
import { BsFillLightbulbFill } from "react-icons/bs";

import Utils from '../../utils';
import { Spiner } from 'components/Bloc';
import { useTranslation } from 'react-i18next';
import { Texto } from 'layouts/TextoLayout';

export default function AddBotom(props){
    const {t} = useTranslation()
   
    const myObserver = new ResizeObserver(entry => {
        if(props.setBottomCalculedHeight){
            props.setBottomCalculedHeight(window.$('#BotomAdd').height())
        }
    });
    useEffect(() =>{
        const someEl = document.getElementById('BotomAdd');
        myObserver.observe(someEl);
        return () =>  myObserver.disconnect();
    }, [])
    function calculVat(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return (Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (14.975 / 100)).toFixed(2)
    }

    function finalPrice(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return ( Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (1 + 14.975 / 100)).toFixed(2)
    }

    const renderCurency = (veryMin) => (
        <span className={`${!veryMin ? "text-xs": "text-[11px]"}  font-bold`}>
            {Utils.renderCurencyCode()}
        </span>
    )
    return(
        <div className='' >
            <div id="BotomAdd" className={' fixed py-3 bottom-0 z-[5] dark:opacity-70 left-0 md:left-60 2xl:left-72 right-3 dark:text-darkTextColor dark:bg-bgPagedark   shawdow  '}>
                
                <div className='flex flex-wrap gap-4 items-end justify-center'>
                    
                    <div className='w-64 xl:w-72 2xl:w-80  text-left text-sm 2xl:text-base text-primary dark:text-darkTextColor'>
                        <Texto className='font-bold'>{t("deploy.deployBottom.summary")}:</Texto> 
                        {!props?.isKube ?
                            <>
                                <div className='flex gap-2'>
                                    {t("deploy.deployBottom.location")}:{" "}
                                    <Texto small className='font-semibold'>
                                        <>{props.vps_params?.locationO?.name || t("deploy.deployBottom.valueNotDefined") }</>
                                    </Texto>
                                </div>
                                {!props?.isKps && !props?.isSecurity && !props?.isNetwork ?
                                    <div className='flex gap-2'>
                                        {t("deploy.deployBottom.image")}:{" "}
                                        <Texto small className='font-semibold'>
                                             <>{props.vps_params?.image || t("deploy.deployBottom.valueNotDefined") }</>
                                        </Texto>
                                    </div>
                                    :
                                    null
                                } 
                                <Texto small className='font-semibold'>
                                    {t("deploy.deployBottom.plan")}:{" "}
                                    <>{
                                        props.packSelected?
                                            <>
                                                {props.packSelected?.Name}
                                                &nbsp;-&nbsp;
                                                {renderCurency(true)}
                                                {Utils.priceConvert(props.packSelected?.Price).toFixed(2)}{" "}
                                                {t("deploy.deployBottom.perMonth")}
                                            </>
                                            : 
                                            t("deploy.deployBottom.valueNotDefined") 
                                        }
                                    </>
                                </Texto> 
                            </>
                            :
                            null
                        }
                        <Texto small className='font-semibold'>
                            TPS + TVQ:{" "}
                            {calculVat() ? 
                                <>
                                    {renderCurency(true)}
                                    {calculVat()}
                                </> 
                                : 
                                t("deploy.deployBottom.valueNotDefined")
                            }
                        </Texto> 
                        <div className="flex gap-2 items-end">
                            <Texto small className='font-bold'>{t("deploy.deployBottom.tAmount")}: &nbsp;</Texto>
                            {finalPrice () ?
                                <>
                                    {renderCurency()}
                                    <span className='text-base xl:text-xl 2xl:text-2xl font-bold'>
                                        {finalPrice()}
                                    </span>
                                </>
                                :
                                <Texto normal className='font-semibold'>
                                    {t("deploy.deployBottom.valueNotDefined")}
                                </Texto>
                            }
                            <Texto verySmall className="font-semibold xl:text-sm">
                                { finalPrice () ? t("deploy.deployBottom.perMonth") : ""}
                            </Texto>
                        </div>
                    </div>
                    <div className=''>
                        <button className="
                            flex 
                            mx-auto 
                            2xl:text-xl 
                            xl:text-base 
                            text-sm  
                            w-60 
                            xl:w-64  
                            2xl:w-72 
                            text-center
                            items-center
                            shadow
                            bg-primary
                            focus:shadow-outline 
                            focus:outline-none 
                            text-white 
                            font-bold 
                            2xl:py-4 2x:px-4 
                            xl:py-3 xl:px-3
                            py-2 px-2
                            rounded
                            "
                            type="submit"
                            disabled={props.creating}
                        >
                            {!props.creating ?
                                <span className='mx-auto flex  items-center'>
                                    <BsFillLightbulbFill className='text-2xl' />
                                    { props.soon ? t("deploy.deployBottom.comingSoon") : t("deploy.deployBottom.deployNow")}
                                </span>
                                :
                                <span className='mx-auto flex gap-1 items-center'>
                                    <Spiner fontSize={30} />
                                    {/* <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> */}
                                    {!props.paypal ? t("deploy.deployBottom.deploying") :  t("deploy.deployBottom.waitingForpaypal")}
                                </span>

                            }
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
