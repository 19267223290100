export default{
    settingsPopup: {
        language: "Language",
        browserDefault: "Browser default",
        en: "English",
        fr: "French",
        viewMode: "View mode - beta",
        light: "Light",
        dark: "Dark",
        more: "More user settings",
        myAccount: "My Account",
        logout: "Logout"
    }
}
