import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { AddCloudServiceForm } from 'components/vps/AddCloudServiceForm';
import { VpsMiniPack } from 'components/vps/Packages';
import { useTranslation } from 'react-i18next';

import debian from '../../../assets/img/debian.png'
import ubuntu from '../../../assets/img/ubuntu.png'
import { AddServiceSectionTitle } from '../../../components/Bloc';
import VpsImage from '../../../components/VpsImage'
import UserMgt from '../../../lib/user_managment';
import VPSapi from '../../../lib/vps_service';
import Utils from '../../../utils';
import  DeployServerWrapper from '../../DeployServerWrapper';

const imgs = {
    ubuntu,
    debian
}


export default function AddVpsMini(props) {
    const {isTrial, isPromo} = props
    const {t} = useTranslation()
    const images = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "Debian"];
        const Tab = []
        if (app.vps && app.vps.images) {
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i];
                const concerts = imgs.filter(img => (img.name.toLowerCase()).includes(os.toLowerCase()))
                Tab.push({ name: os, list: concerts, id: i })
            }
            return Tab
        }
        return null;
    })
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)

    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)

    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: null,
        username: "",
        password: "",
        location: null,
        plan_id: null,
        planName: null,
        trialCode: null,
        promoCode: null,
        isAdminFreeDeployment: null
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)



    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }

        return vps_packages
    }

    

    useEffect(() => {
        getVpsTypes().then(vps_packages => {

            if (Utils.getUrlParam('prefer')) {
                const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                if (pack) {
                    setClicked(parseInt(selectedIdVpsTypes));
                    onClaculFlavor(pack)

                }
                window.localStorage.removeItem('@user_first_service')
            }
        })

        loadImages()
    }, [])

    async function functionFinish(){
        await UserMgt.get_user_vps()
        navigate('/compute/vpsmini')
    }
    function validate(){
        if (!vps_params.image) {
            return {
                isFail: true,
                message: "Image not selected"
            }
        }
        if (!vps_params.vpsTypeId) {
            return {
                isFail: true,
                message: "Package not selected"
            }
        }
        if (!vps_params.location) {
            return {
                isFail: true,
                message: "Service location not selected"
            }
        }

        if (vps_params.password.length < 6) {
            return {
                isFail: true,
                message: "Password must be at least 6 characters"
            }
        }
        if (Utils.testPassworsVps(vps_params.password)) {
            return {
                isFail: true,
                message: "Password should not contains empty space"
            }
        }
        if (vps_params.passwordCon !== vps_params.password) {
            return {
                isFail: true,
                message: "Passwords did not match"
            }
        }
        if (isPromo && !codeApplied) {
            return {
                isFail: true,
                message: "You should apply promo code !"
            }
        }
        return {
            isFail: false
        }
    }

    function onClaculFlavor(pack) {
        if(pack) setPack({...pack})
        else setPack(null)
        setVpsParams({
            ...vps_params, 
            plan_id: (isTrial? pack?.PaypalPackIdFree : pack?.PaypalPackId),
            vpsTypeId: pack?.Id
        }) 

    }




    const concertPack = vps_packages &&
        vps_packages.filter(p => p.service?.task_name === "vpsmini")
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))
    return (
        <DeployServerWrapper
            paypal={paypal}
            packSelected={packSelected}
            isTrial={isTrial}
            isPromo={isPromo}
            isVpsMini={true}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            handleChange={handleChange}

            codeApplied={codeApplied}
            codeApplying={null}
            type={"VSPmini"}
            functionFinish={functionFinish}
            setPaypal={setPaypal}
            validate={validate}
            setCreating={setCreating}
            setCodeApplied={setCodeApplied}
            setCodeApplying={()=>{}}
        >   
            <div> 
                <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                    <VpsMiniPack
                        concertPack={concertPack}
                        setSelected={setSelected}
                        creating={creating}
                        clicked={clicked}
                        setClicked={setClicked}
                        onClaculFlavor={onClaculFlavor}
                        setPackPaypal={()=>{}}
                        selected={selected} 
                        noMxauto
                    />
                </div>
                <AddServiceSectionTitle
                    title={t("deploy.pickInstanceImageSectionTitle")}
                />

                <div className="mx-auto relative  justify-center pb-5 border-b-2 dark:border-gray-800 flex flex-wrap 2xl:gap-10 md:gap-6 gap-3  mt-5">
                    {images && images.filter(image => image.name.includes("bun") || image.name.includes("bian")).map((image, k) =>
                        <VpsImage
                            key={k}
                            image={image}
                            creating={creating}
                            clickedImage={clickedImage}
                            setClickedImage={setClickedImage}
                            setVpsParams={setVpsParams}
                            vps_params={vps_params}
                            setSelectedImage={setSelectedImage}
                            selectedImage={selectedImage}
                            uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                        />
                    )}
                </div>

                <AddServiceSectionTitle
                    title={t("deploy.serverInformationSectiontitle")}
                />
                <div className={'w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10 '}>
                    <AddCloudServiceForm
                        creating={creating}
                        paypal={paypal}
                        vps_params={vps_params}
                        handleChange={handleChange}
                    />
                </div>
            </div> 
        </DeployServerWrapper>
    );
}




