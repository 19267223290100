import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ServerLocation } from 'components/vps/ServerLocation';
import { Texto } from "layouts/TextoLayout";

import { PaymentMethodWorker } from '../../../components/billing/Payment'
import { SizeCard } from '../../../components/volume/VolumeSection'
import { VPS_MANAGE } from '../../../constants/link'
import STORAGE from '../../../lib/storage';
import UserMgt from '../../../lib/user_managment';
import Utils from "../../../utils"

export default function AddVolumeStorage(){ 
	const Id = Utils.getUrlParam('selected')
	const {t} = useTranslation()

	const list = useSelector(app => {
        return app.vps?.list?.filter(s => s.service?.task_name !== "vpsmini") || null
    })
  	const  paymentMethods  = useSelector(app => app.core?.paymentMethods?.filter(m => !!m.isValidated))


   const {messageApi} = useSelector(app => app.core )
   const navigate = useNavigate()

	function border(id, inspect){
		return id === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
	}

	const [sizes, setSizeP] = useState(null)
	const [creating, setCreating] = useState(false)
	const [createSubscriptionStart, setCreateSubscriptionStart] = useState(false)
	const [addNewPaymentM, setAddPaymentMedthod] = useState(false)

	const [data, setData] = useState({
		type: "ssd",
		configOption: "auto",
		fileSystem: "ext4"
	});

  	const onChange = (e) => {
	    setData({
	    	...data,
	    	type: e.target.value
	    });
  	};

  	

  	// async function getVolumeSize(){
  	// 	const data = await STORAGE.getVolumeSize()
  	// 	if(!data?.error){ 
  	// 		setSize(data?.sizes?.sort((a, b) => -b.id + a.id))
  	// 	}
  	// }

  	async function loadVps(){
        !list && await UserMgt.get_user_vps() 
        if(Id){
        	setData({
        		...data,
        		serverId: Id
        	})
        }
    }

    async function volumeOnCrete(ev){
    	ev.preventDefault()
    	let wrong = false
    	if(!data?.sizeId){
    		messageApi.open({
              type: 'error',
              content: t('volume.createComponent.vSizeFieldReq'),
            });
            wrong=true
    	}
    	const size = sizes.find(s => s.id == data?.sizeId)
    	if(size && size?.isCustom && !data?.customSize){
    		messageApi.open({
              type: 'error',
              content: t('volume.createComponent.vSizeInGBReq'),
            });
            wrong=true
    	}
    	if(!data?.location){
    		messageApi.open({
              type: 'error',
              content: t('volume.createComponent.specifyRegion'),
            });
            wrong=true
    	}

        if(wrong){
        	return //
        }
        if(!paymentMethods || !paymentMethods.length){
    		return setCreateSubscriptionStart(true)
        } else {
			return payAndDeploy()
		}
        // return setAddPaymentMedthod(true)
    }

    async function payAndDeploy(paymentMethodId){
    	setCreating(true)
    	setCreateSubscriptionStart(false)
        const result = await STORAGE.createVolume(
        	{
        		...data,
        		// paymentMethodId
        	}
        )
        setCreating(false)
        if(result?.volume && !result?.error){
			await STORAGE.list()
			if(Id){
		 		navigate(VPS_MANAGE(Id)+"?act=s")
			} else {
				navigate(-1)
			}
			if(window.$crisp){
				const product_bought = [
					"product_bought", {
						type: "Block Storage",
						vol_type: data.type,
						size: sizes,
						price: data.type === 'ssd'? sizes * 0.1 : sizes * 0.05 //@TODO Change this to a function calculating the cost using the price from the db
					},
					"green"
				]
				window.$crisp.push(["set", "session:event", [[ product_bought ]]]);
				window.$crisp.push(["set", "session:segments", [["block_storage"]]]);
			}
			messageApi.open({
				type: 'success',
				content: t('volume.createComponent.vCreated'),
			});
        }else{
        	messageApi.open({
	            type: 'error',
	            content: result?.message || t('core.constants.STANDARD_ERROR_MESSAGE'),
	        });
        }
    }
   

    useEffect(()=>{ 
        loadVps() 
    }, [])

  	
	return (
		<div className="px-3 md:px-8 volume pb-10">
				<PaymentMethodWorker
					open={createSubscriptionStart}
					setOpen={setCreateSubscriptionStart}
					payAndDeploy={payAndDeploy}
				/>
				<form className="mx-auto lg:w-[880px]" onSubmit={volumeOnCrete}>
					<div className="w-full">
						<div className="mt-5 pb-5">
							<ServerLocation 
								vps_params={data} 
								setVpsParams={(toSet)=> setData({...data, ...toSet, serverId: null})} 
							/>
						</div>
						<div className="mt-5 pb-5">
							<Texto normal className="font-semibold">
								{t('volume.createComponent.selectType')}  
							</Texto>
						</div>
						<div className=" w-full">
							<Radio.Group required onChange={onChange} value={data?.type} className="w-full">
								<div className="flex flex-wrap justify-between ">
									<div 
										className={`
											border
											border-2
											${border("ssd", data?.type)}
											px-6
											py-8
											cursor-pointer
											flex
											w-[430px]
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, type: 'ssd'})}
									>
								    	<Radio value={"ssd"}></Radio>
										<Texto small className="">
											<span className="font-semibold text-base 2xl:text-xl">
												{t('volume.createComponent.ssd')}  
											</span>
											<br/>
											{t('volume.createComponent.ssdDesc')}  
										</Texto>
									</div>
									<div 
										className={`
											border
											border-2
											${border("hdd", data?.type)}
											px-6
											py-8
											w-[430px]
											cursor-pointer
											flex
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, type: 'hdd'})}
									>
								    	<Radio value={"hdd"}></Radio>
										<Texto small className="">
											<span className="font-semibold text-base 2xl:text-xl">
												{t('volume.createComponent.hdd')}  
											</span>
											<br/>
											{t('volume.createComponent.hddDesc')}  
										</Texto>
									</div>
									
								</div>
						    </Radio.Group>
						</div>
					</div>
					<div className="w-full">						
						<SizeCard setSizeP={setSizeP} data={data} setData={setData} />
					</div>
					<div className="w-full flex flex-wrap justify-between gap-3 mt-5">
						<div className="w-[430px] ">
							<div className="mt-5 pb-5">
								<Texto normal className="font-semibold">
									{t('volume.createComponent.selectServices')}  
								</Texto>
							</div>
							<div> 
								<Select
								    // showSearch
									size="large"
									value={data?.serverId}
									className="w-full rounded-none"
									// dropdownStyle={{borderRadius: "0px"}}
								    placeholder={t('volume.createComponent.selectDropdown')}
								    // optionFilterProp="children"
								    onChange={(selected) => setData({...data, serverId: selected})}
								    // onSearch={onSearch}
								    // filterOption={filterOption}
								    options={list &&list.filter(vps => vps.VpsStatus !== "Deploying" && vps.VpsStatus !== "Pending" && vps.locationId === data?.location).map(vps=>{
								    		return {value: vps.Id, label: vps?.DisplayName}
								    	})
								    }
								    required
								/>
							</div>

						</div>

						<div className="w-[430px]">
							<div className="mt-5 pb-5">
								<Texto normal className="font-semibold">
									{t('volume.createComponent.nameV')}  
								</Texto>
							</div>
							<div>
								<Input 
									onChange={(e) => setData({...data, name: e.target.value})} 
									placeholder={t('volume.createComponent.nameV')}
									size="large"
									required
									className="w-full rounded-none"
								/>
							</div>
						</div>
					</div>

					<div className="w-full mt-5 ">
						<div className="pb-5">
							<Texto normal className="font-semibold ">
								{t('volume.createComponent.chooseConfigOptions')}  
							</Texto>
						</div>
						<div className=" w-full">
							<Radio.Group 
								onChange={(e)=>setData({...data, configOption: e.target.value})} 
								value={data?.configOption} 
								className="w-full"
								required
							>
								<div className="flex flex-wrap justify-between ">
									<div 
										className={`
											border
											border-2
											${border("auto", data?.configOption)}
											px-6
											py-8
											cursor-pointer
											flex
											w-[430px]
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, configOption: 'auto'})}
									>
								    	<Radio value={t('volume.createComponent.autoConfig')}></Radio>
										<Texto small className="">
											<span className="font-semibold text-base 2xl:text-xl">
												{t('volume.createComponent.authMount')}  
											</span>
											<br/>
											{t('volume.createComponent.autoMountDesc')}  
										</Texto>
									</div>
									<div 
										className={`
											border
											border-2
											${border("manual", data?.configOption)}
											px-6
											py-8
											w-[430px]
											cursor-pointer
											flex
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, configOption: 'manual'})}
									>
								    	<Radio value={t('volume.createComponent.manualConfig')}></Radio>
										<Texto small className="">
											<span className="font-semibold text-base 2xl:text-xl">
												{t('volume.createComponent.manualConfigT')}  
											</span>
											<br/>
											{t('volume.createComponent.manualConfigTxt')}  
										</Texto>
									</div>
									
								</div>
						    </Radio.Group>
						</div>
					</div>

					<div className="w-full mt-5 ">
						<div className="w-full pb-5 flex gap-12">
							<Texto normal className="font-semibold">
								{t('volume.createComponent.chooseAFileSystem')}  
							</Texto>
							<Radio.Group 
								onChange={(e)=>setData({...data, fileSystem: e.target.value})} 
								value={data?.fileSystem} 
								className=""
								required
							>
						    	<Radio value={"ext4"}>EXT4</Radio>
								
						    	<Radio value={"xfs"}>XFS</Radio>
					
						    </Radio.Group>
						</div>
					</div>
					<div className={`mt-5`}>
						<div className="pb-5">
							<Texto normal className="font-semibold">
								{t('volume.createComponent.addOpt')}  
							</Texto>
						</div>
						<div className="pb-4 my-5 border-2 rounded-md border-gray-300 w-full   shadow-lg">
							<div className="text-base bg-neutral-100 p-6 2xl:text-xl flex gap-2">
								<InfoCircleFilled className="text-blue-500 text-2xl" />
								<span className="font-semibold">
									{t('volume.createComponent.addOptTxt')}  
								</span>

							</div>
							<div className="p-4 text-sm 2xl:text-base border-t-2 border-gray-300">
								<Texto normal className="font-bold">
									{t('volume.createComponent.getExta')}  
								</Texto>
								<Texto small className="mt-3 font-semibold ">
									{t('volume.createComponent.getExtraTxt')}  
								</Texto>
							</div>
							<div className="pt-4 px-4 flex justify-between border-t-2 border-gray-300">
								<div>
									
									<Checkbox 
										className="text-sm md:text-base xl:text-xl font-bold" 
										size='large'
										disabled={creating} 
										onChange={(e) =>{
											setData({
													...data, 
													encrypted: e.target.checked
												})
										} }
									>
										{t('volume.createComponent.addEncryp')}  
									</Checkbox>
								</div>
								<div className="text-primary text-sm md:text-base xl:text-xl">
								</div>
							</div>
						</div>
					</div>
					<div className="w-full mt-5 ">
						<span className="font-semibold text-base 2xl:text-xl">
							{t('volume.createComponent.storageSameRegion')}  
						</span>
					</div>
					<div className="w-full py-3 ">
						<Divider />
					</div>
					<div className="w-full py-3 ">
						<Button 
							className="mx-auto w-full font-semibold h-10 2xl:h-12 " 
							style={{borderRadius: "0px"}} 
							type="primary"
							htmlType="submit"
							loading={creating}
							// onClick={
							// 	//()=>volumeOnCrete()
							// }
						>
							{t('volume.createComponent.createBtn')}  
						</Button>
					</div>
				</form>
				
		</div>
	)
}