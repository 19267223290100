import en from "./en"

export default{
        connectSshLink: "Connect using SSH",
        copyToClip: "Copy to clipboard",
        copied: "Copied",
        new: "New",
        prev: "Prev",
        next: "Next",
        addPlan: "Add Plan",
        learn: "Learn",
        dashboard: {
                "goodMorning": "Good morning",
		"goodAfternoon": "Good afternoon",
		"goodEvening": "Good evening",
                "searchPlaceholder": "Filter by name, location, type or tag",
                "tab1": {
                        title: "Ressources",
                        createService: "Create an instance and get started with KeepSec!",
                        noService: "You have no services right now",
                        lean: "Lean more about our services",
                        compute: "COMPUTE",
                        kubernetes: "KUBERNETES",
                        security: "SECURITY",
                        networks: "NETWORKING",
                        createNow: "Create something now",
                        createVps: "Create a VPS",
                        vpsDesc: "Add a cloud server now",
                        setLoad: "Setup a Load Balancer",
                        setLoadText: "Distribute traffic between multiple services",
                        createKc2: "Create a KC2",
                        kc2text: "Add a KC2 server now",
                        addOnOne: "Add on what you have",
                        createVolum: "Create a Volume",
                        upgradeStorage: "Upgrade your service storage now"
                },
                "tab2": {
                        title: "Activity",
                }
	},
        service: {
                miniCard: {
                },
                deploying: "Deploying",
                tootipConsole: "Go to console",
                tootipMAnage: "Go to manage page",
                startServiceTooltip: "Start server first before using console",
                connect: "Connect",
                connect2: "Connect",
                goListAppPAGE: "Go to list app page",
                manage: "Manage",
                addBlockStorage: "Add block storage",
                stop: "Stop",
                start: "Start",
                reboot: "Reboot",
                delete: "Delete",
                running: "Running",
                stopped: "Stopped",
                //khks
                ha: "High Availability",
                viewNodes: "View nodes",
                downConfig: "Download config",
                enabled: "Enabled",
                notEnabled: "Not enabled",
        },
        volume: {
                create: "Create a Volume",
                lookDOntHave: "Looks like you don't have any block storage volumes",
                desc: "Volumes are highly available units of block storage that you can attach to a VPS to increase its available space.",

                volumes: "Volumes",
                bsb: "Block storage basics",
                discover: "Discover block storage and what you can do with volume.",
                overview: "Overview",
                apiDocs: "API docs",
                useStorageviaKeepApi: "Use storage volume via the KeepSec API.",
                tellWhatThink: "Tell us what you think",
                subFeelBack: "Submit your feelback on block storage.",
                createComponent: {
                        selectType: "SELECT VOLUME TYPE",
                        ssd: "Solid state drive (SSD)",
                        ssdDesc: "SSDs deliver high-speed, reliable storage with faster data access.",
                        hdd: "Hard disk drive (HDD)",
                        hddDesc: "HDDs provide cost-effective, high capacity storage ideal for data intensive tasks.",
                        selectServices: "SELECT SERVICE TO ATTACH",
                        selectDropdown: "Select a Service",
                        nameV: "NAME VOLUME",
                        nameVHolder: "Name of volume",
                        chooseConfigOptions: "CHOOSE CONFIGURATION OPTIONS",
                        autoConfig: "auto",
                        manualConfig: "manual",
                        authMount: "Automatically Format & Mount",
                        autoMountDesc: "We will choose the appropriate default configurations. These setting can be changed later via SSH",
                        manualConfigT: "Manually  Format & Mount",
                        manualConfigTxt: "We will still attach the volume. You can then manually format and mount the volume",
                        chooseAFileSystem: "Choose a file system",
                        addOpt: "ADDITIONNAL OPTIONS",
                        addOptTxt: "We recommend encryption to add a security layer and increase peace of mind.",
                        getExta: "Get extra security for critical data",
                        getExtraTxt: "An encrypted volumes with a LUKS with extra aes-xts-plain64 and 256bits keys cipher.",
                        addEncryp: "Add encryption",
                        storageSameRegion: "Block storage volumes are created in the same region and project as the service they are attached to.",
                        createBtn: "Create Volume",
                        vSizeFieldReq: "Volume size field is required!",
                        vSizeInGBReq: "Size in GB field is required!",
                        specifyRegion: "You must specify a region where you are going to create volume",
                        vCreated: "Volume created!",
                },
                detach: {
                        wereDetach: "Server were detached!",
                        serName: "Service Name",
                        mPath: "Mounted path",
                        act: "Action",
                        dtachbtn: "Detach",
                        modalTitle: "Detach instances from volume name {{name}}",
                        cancelBtn: "Cancel",
                        ____: "______",
                        
                },
                sizeModal: {
                        doneMessage: "Volume size upgraded!",
                        modalTitle: "Increase volume nammed {{name}}  size",
                        pickNewSize: "Pick new size to increase.",
                        cancelBtn: "Cancel",
                        setNewSizeBtn: "Set new size",
                        ____: "______",
                        ____: "______",
                        ____: "______",
                        ____: "______",
                        
                },
                attachModal: {
                        doneMessage1: "Instance were attached to volume!",
                        doneMessage: "Volume were attached to instance!",
                        modalTitle: "Attach an instance to volume nammed {{name}}",
                        selectNewIstanceToattach: "Select new instance to attach.",
                        cancelBtn: "Cancel",
                        selectHere: "Select new instance here",
                        selectAService: "Select a Service",
                        attachBtn: "Attach",
                        title2: "Attach volume to an instance",
                        selectNewVomlume: "Select new volume to attach.",
                        selectNewvomuehere: "Select new volume here",
                        selectaVolume: "Select a volume to acttach",
                        ____: "______",
                        
                },
                sizeCard: {
                        selectectNewSize: "SELECT VOLUME SIZE",
                        perHours: "/hour",
                        enterSizeInGB: "Enter size in GB",
                        minValueTootlip: "Minimum value is 10GB",
                        ____: "______",

                },
                OOngoing: "Operaton is ongoing!",
                formatConfirm: "Format {{name}} confirmation",
                formatConfirmText: "Do you realy want to format the volume named {{name}}",
                okFormat: "Yes, I do",
                noFormat: "No, I don't",
                attachBedoreFormat: "Attach an instance to volume {{name}} first before format it",
                clickFOrmat: "Click to format.",
                format: "Format {{name}} ",
                detach: "Detach",
                attach: "Attach",
                increaseSize: "Increase size",
                takeSnapshot: "Take snapshot",
                configIns: "Config instructions ",
                deleteConfirm: "Detach volume {{name}} first before delete",
                deleteConfirm2: "Delete this volume.",
                delete: "Delete",
                name: "Name",
                autoConfig2: "Auto config",
                nanualConfig2: "Manual config",
                serviceTableCol: "Services",
                on: "<b>{{mountPoint}}</b> On  <b>{{DisplayName}}</b>",
                date: "Date",
                more: "More",
                ____: "______",
        },
        deleteCompoent:{
                h1: "Delete this service ?",
                title: "Delete",
                text1: "Deleting this instance will permanently destroy it including all of its data.",
                text2: "Are you sure you want to delete this instance?",
                button: "Yes, Delete",
                cannotDeleteServiceText: "You can't delete this service."
        },
        account: {
                profile: {
                        twoFa: {
                                disbaleTwoFaModal: {
                                        title: "Disable two-factor authentification",
                                        p1: "This reduces the security level of your account and is not  recommended.",
                                        p2: "Are you sure you want to continue?",
                                        yesBtn: "Yes, Disable 2FA",
                                        nobtn: "No, Cancel",
                                        successMessage: "Two-factor auth has been disabled in your account.",
                                        ______: "____",
                                }, 
                                default: "DEFAULT",
                                configured: "Configured",
                                backup: "BACKUP",
                                backupCodes: "Backup Codes",
                                remaining: "remaining",
                                disableBtn: "Disable 2FA",
                                setupZfa: "Set Up 2FA",
                                Zfasetup: "2FA Setup",
                                setTwofaAuth: "Set up two factor authentification",
                                chooseMethod: "Choose an authentification method",
                                methodTxt: "In addition to your username and password, you will have to enter a code (delivered via app or SMS) to sign in to your account",
                                appAUth: "Authentification App",
                                recommended: "Recommended",
                                getTheCode: "Get codes from an app like Authly, 1Password, Microsoft Authentificator or Google Authentificator",
                                SMS: "SMS",
                                receiveSMS: "Receive codes via SMS",
                                verifyHone: "Verify your phone number",
                                verifyNumTxt: "Enter you cell phone number and we will send you a  verification code",
                                sendSMS: " Send SMS",
                                connectApp: "Connect your app",
                                connectApptxt: "Using an authentification app, scan the QR code below",
                                copyQr: "Or copy the secret key for manual entry",
                                onceConnectAppTxt: "Once you have connected your app, enter your most recent 6 digits verification code for",
                                digitCode: "6 digits code",
                                saveBackupCode: "Save backup codes",
                                saveBackupTxt: "Make a copy of your codes, store them using a password manager or in a encrypted file",
                                cpyBtn: "Copy",
                                downTxt: "Download as .txt file",
                                warningTxt: "If you lose access to your default authentification method, use one of these codes to regain access to your account.",
                                prev: "Previous",
                                cancel: "Cancel",
                                next: "Next",
                                confirm: "Confirm",
                                preventTxt: "Are you really sure that you saved your backup codes ?",
                                yes: "Yes, I am",
                                no: "No, I'm not",
                                finish: "Finish",
                                methodNotready: "This 2FA method is not ready yet.",
                                erro6Digits: "6 digits code is incorrect.",

                                choseMethod: "Choose method",
                                verify: "Verify",
                                backCode: "Backup codes",
                                ______: "____",
                        },
                        profileTab: {
                                name: "Profile",
                                firstName: "First name",
                                lastName: "Last name",
                                email: "Email",
                                phonenumber: "Phone number",
                                company: "Company",
                                address: "Address",
                                address2: "Address 2",
                                city: "City",
                                ZipCode: "Zip code",
                                website: "Company website",
                                descirptionTextBefore: "Describe the service(s) you are running with your KeepSec account",
                                desc: "Description",
                                ______: "____",
                        },
                        preferencesTab: {
                                name: "Preferences",
                                title: "User Preferences",
                                light: "Light mode",
                                dark: "Dark mode",
                                dTimezone: "Display Timezone",
                                timezonePlaceholder: "Enther the current password",
                                dateFormat: "Date Format",
                                choice1: "Choice 1",
                                updateProfilBtn: "Update profile",
                        },
                        authentificationTab: {
                                name: "Authentification",
                                title: "Two-Factor Authentification",
                                p1: "Keep your account secure by enabling two-factor authentification.",
                                p2: "Each time you log in you will be required to provide your password and a randomly generated access code",
                                currentPass: "Current password",
                                currentPassPlaceholder: "Enther the current password",
                                newPass: "New password",
                                newPassPlaceholder: "Enter the new password",
                                confirmNew: "Confirm new password",
                                confirmNewPlcaholder: "Enter the new password confirmation",
                                ongoing: "Ongoing",
                                change: "Change Password",
                                ______: "____",
                        },
                        securityTab: {
                                name: "Security",
                                ______: "____",
                                ______: "____",
                                ______: "____",
                        },
                },
                reward: {
                        token: "Token",
                        click: "Click",
                        reg: "Register",
                        refPur: "Referal Purchase",
                        xp: "63XP to level 3",
                        level: "Level",
                        getRewardTab: {
                                name: "Get Reward",
                                copyToclip: "Copy to clipboard",
                                copied: "Copied",
                                inviteViaEmail: "Invite a friend through email",
                                emailInputPlaceholder: "Enter email address",
                                invite: "Invite",
                                share: "Share through social media",
                                criteria: "Criteria for new referrals",
                                newUsrOnly: "1. <b>New users only.</b> The person you're sharing the link to cannot have any existing account.",
                                rewardAre: "2. Rewards are only eligible for first-time purchases.",
                                theRef: "3. The referred client does not refund and is <b>active for at least 30 days.</b>",
                                youWill: "You will receive a 20% payment rounded of the purchased services plan price in form of tokens.",
                                theRef2: "The referred person will get a 5% discount on the service until cancelation.",
                                theOnce: "Once you have collected tokens from your referrals, you wil be able to redeem them for items in the store.",
                                toBeAble: "To be able to withdraw your tokens in money you will need a least 50 tokens, one token is equal to $1 CAD.",
                                term: "Terms of service",
                                labelReq: "{{label}} is required!",
                                notValidEMail: "{{label}} is not a valid email!",
                                notValidPhone: "{{label}} is not a valid number!",
                                ____: "_____",
                        },
                        storeTab: {
                                name: "Store",
                                ____: "_____",
                        },
                        winpriceTab: {
                                name: "Win Prize",
                                ____: "_____",
                        },
                        arcadesTab: {
                                name: "Store",
                                ____: "_____",
                        },
                        wthdrawTab: {
                                name: "Withdraw",
                                ____: "_____",
                        },
                        historyTab: {
                                name: "History",
                                ____: "_____",
                        },
                        ____: "_____",
                        ____: "_____",
                },
                billing: {
                        overview: {
                                name: "Overview",
                                viwKube: "View Kubernetes",
                                viwNet: "View Networking",
                                title: "Overview",
                                explanation: "This is an estimate of the amount you own based curent month-to-date usage after credit & prepayments.",
                                extDue: "Estimated Due",
                                paymentDue: "Payment due",
                                prepayment: "Prepayments",
                                date: "April, 01 2025",
                                totalUsage: "Total Usage",
                                monthToDate: "Month-to-Date summary March 1 - 5, 2024",
                                thesCharge: "These charges are factored into your account balance.",
                                expand: "Expand all",
                                down: "Download",
                                subT: "Subtotal",
                                tax: "Taxes",
                                total: "TOTAL",
                                viewIn: "View invoice",
                                unknown: "Unknow",
                                amunt: "Amount",
                                desc: "Description",
                                status: "Status",
                                succ: "Success",
                                err: "Error",
                                billingHist: "Billing history",
                                paymentMethod: {
                                        addBtn: "Add Payment Method",
                                        pymentsM: "Payment Methods",
                                        primary: "Primary",
                                        longText: "The primary payment method is automatically charged at the close of each billing cycle or can be used to prepaid your bill.",
                                        whenAuto: "When the primary payment method fails, a backup payment method pays the balance automatically.",
                                        backup: "Backup",
                                        noBackup: "(No backup payment method found!)",
                                        noteInfo: "Informational Notes",
                                        youCurr: "You currently don't have any saved payment methods.",
                                        promos: "Promos",
                                        cpromo: "Promo Code",
                                        havePromo: "If you have a promo code, please enter it below to receive your credit.",
                                        addPromoCode: "Add new promo code",
                                        apllyCode: "Apply Code",
                                        delete: "Delete",
                                        setPrimary: "Set as primary",
                                        allText: "payment method icon",
                                        creditcard: "Credit Card",
                                        addCreditCard: "Add a credit card",
                                        acceptable: "We accept a Visa, MasterCard, American Express, UnionPay and Discover",
                                        connectpaypal: "Connect Paypal",
                                        connectPText: "Connect your paypal account for automatic payments",
                                        addmethodTitle: "Add a new payment method",
                                        selectAType: "Select a Payment method type to add",
                                        fillForm: "Fill the form ",
                                        connYourPaypal: "Connect your PayPal",
                                        chooseDiff: "Choose a different payment method",
                                        letSet: "Let's set it up",
                                        signPaypal: " Sign in to PayPal by clicking on the button below and we will add it as a recurring payment method to your account for feature use.",
                                        informatioNot: "Informational Notes",
                                        informNotesContent: "<b>Notes:</b>&nbsp; In order to connect PayPal as a saved payment method, you must initiate a transaction of <b>$5.00</b> to verify your account, this charge will be applied to your a first bill and only need to be setup once.",
                                        Cancel: "Cancel",
                                        loanding: "Loanding...",
                                        oCanceled: "Operation cancelled !",
                                        checkpaypalConfig: "An error occurred on PayPal. Please check if your PayPal account is correctly configured.",
                                        selectPaypMethod: "Select a payment method",
                                        chooseAnother: "Choose another payment method",
                                        noBackupFound: "(No backup payment method found!)",
                                        cancel: "Cancel",
                                        submit: "Submit",
                                        paymicon: "payment method icon",
                                        ____: "_________",

                                },
                                ____: "_________",
                        }, 
                        info: {
                                name: "Payment Info",
                                title: "No Payment Due",
                                p1: "You account is paid in full and  no payement is due at this time.",
                                mkPayment: "Make a Payment",
                                lastInv: "View last invoice",
                                credit: "Credit",
                                addFund: "Choose credit amount",
                                ____: "_________",
                                
                        },
                        credit: {
                                name: "Credit",
                                summ: "Summary",
                                ksPointAndPrice: "KsPoint and  Price: <i>(To be defined) </i> ",
                                empty: "(empty)",
                                payWithPaypal: "Pay with paypal",
                                paying: "Paying",
                                waitpaypal: "Waiting for paypal",
                                iAgree: "I agree to the",
                                term: "Terms of Service",
                                ____: "_________",
                        },
                        hist: {
                                name: "History"
                        }
                }
        },
        ...en,
} 