import { useTranslation } from 'react-i18next';
const { useState } = require('react')
const { Tooltip } = require("antd");
const { LabelAdd, InputTextAdd, InputPassAdd } = require("../Bloc");


export function AddCloudServiceForm({creating, vps_params, handleChange}){
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const {t} = useTranslation()

    return(
        <div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                    <LabelAdd
                        htmlFor="serv-name"
                        placeholder={t("deploy.identifyYourInstance")}
                    />
                    <Tooltip open={hoverServerName}   title={t("deploy.serverNameTooltip")}>
                        <InputTextAdd
                            idx="serv-name"
                            value={vps_params.server_name}
                            name="server_name"
                            pattern="[A-Za-z0-9]{1,}"
                            onChange={handleChange}
                            required
                            hover
                            onHover={(state) => setHoverToolTopServerName(state)}
                            disabled={creating}
                            placeholder={t("deploy.identfyInstancePlaceholder")}
                        />
                    </Tooltip>
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full xl:w-1/2 px-3">
                    <LabelAdd
                        htmlFor="serv-username"
                        placeholder={t("deploy.instanceUser")}
                    />
                    <InputTextAdd
                        idx='serv-username'
                        value={vps_params.username}
                        name="username"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                        placeholder={t("deploy.instanceUserPlaceholder")}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">

                <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                    <LabelAdd
                        htmlFor="serv-pass"
                        placeholder={t("deploy.instancePassword")}
                    />
                    <InputPassAdd
                            
                        idx="serv-pass"
                        value={vps_params.password}
                        name="password"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        placeholder={t("deploy.sshPasword")}
                    />
                </div>
                <div className="w-full xl:w-1/2 px-3">
                    <LabelAdd
                        htmlFor="serv-pass-confirm"
                        placeholder={t("deploy.passwordConfirm")}
                    />
                    <InputTextAdd
                        idx='serv-pass-confirm'
                        type="password"
                        value={vps_params.passwordCon}
                        name="passwordCon"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        placeholder={t("deploy.passwordConfirmPlaceholder")}
                    />
                </div>
            </div>                                      
        </div>                                                                                     
    )
}
